// Generated by Framer (3f9fff0)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Noise from "https://framerusercontent.com/modules/bamMgJhjMszHbcnxCtZj/20nHFbpxFqdlchR1dDZF/Noise.js";
const NoiseFonts = getFonts(Noise);

const cycleOrder = ["Qp1lvHyz3"];

const serializationHash = "framer-OLaVq"

const variantClassNames = {Qp1lvHyz3: "framer-v-6srcp6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Qp1lvHyz3", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-6srcp6", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Qp1lvHyz3"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1022cxs-container"} data-framer-name={"Noise Background"} layoutDependency={layoutDependency} layoutId={"w0fB3y8yR-container"} name={"Noise Background"}><Noise backgroundSize={128} borderRadius={0} height={"100%"} id={"w0fB3y8yR"} layoutId={"w0fB3y8yR"} name={"Noise Background"} opacity={0.1} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OLaVq.framer-1y0teet, .framer-OLaVq .framer-1y0teet { display: block; }", ".framer-OLaVq.framer-6srcp6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", ".framer-OLaVq .framer-1022cxs-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OLaVq.framer-6srcp6 { gap: 0px; } .framer-OLaVq.framer-6srcp6 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OLaVq.framer-6srcp6 > :first-child { margin-left: 0px; } .framer-OLaVq.framer-6srcp6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerN8E8ewa9l: React.ComponentType<Props> = withCSS(Component, css, "framer-OLaVq") as typeof Component;
export default FramerN8E8ewa9l;

FramerN8E8ewa9l.displayName = "Noise";

FramerN8E8ewa9l.defaultProps = {height: 800, width: 1200};

addFonts(FramerN8E8ewa9l, [{explicitInter: true, fonts: []}, ...NoiseFonts], {supportsExplicitInterCodegen: true})